<template>
  <!--begin::Container-->
  <div class="wrapper">
    <div class="section-shaped my-0 skew-separator skew-mini ">
      <v-img
        class="page-header header-filter  header-small "
        max-height="250px"
        lazy-src="/media/biz-header.jpg"
        src="/media/test-biz-header.png"
        gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
      >
        <div class="container " style="padding-bottom: 65px;">
          <div class="md-layout justify-md-center ">
            <div
              class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
            >
              <div class="lato-header  font-weight-bolder text-white"></div>
              <div class="mt-5">
                <h4 class="text-white "></h4>
              </div>
            </div>
          </div>
        </div>
      </v-img>
    </div>

    <div class="main main-raised" style="background:#fff;">
      <div class="container">
        <div class="text-lg-right">
          <v-btn
            v-if="orders.length > 0"
            elevation="9"
            rounded
            color="grey"
            @click="$router.go(-1)"
          >
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="container">
            <div class="row  ">
              <div class="col-lg-12">
                <v-card class="card card-custom card-stretch">
                  <div class="card-header card-header-tabs-line">
                    <div class="card-title">
                      <h3 class="card-label">Order Return Items</h3>
                    </div>
                    <div class="card-toolbar"></div>
                  </div>

                  <div class="card-body">
                    <!--begin: Datatable-->
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-left">Line ID</th>
                          <th style="width:280px;">SKU</th>
                          <th>Thumb</th>
                          <th style="width:280px;">Item</th>
                          <th style="width:100px;">Qty Returned</th>
                          <th class="text-left">Price</th>
                          <th class="text-left">Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, idx) in orders" :key="idx">
                          <td>{{ idx + 1 }}</td>
                          <td>{{ item.sku_id }}</td>
                          <td>
                            <v-img
                              class="d-flex align-self-center mr-3 rounded-circle"
                              :src="
                                item.url != null
                                  ? baseurl + item.url
                                  : '/media/rollerskate.png'
                              "
                              alt="Generic placeholder image"
                              width="50"
                              height="50"
                            >
                            </v-img>
                          </td>
                          <td>
                            {{ item.product_name }}
                            <span v-if="item.item_name != 'default'"
                              >({{ item.item_name }})</span
                            >
                          </td>
                          <td>
                            {{ item.qty_to_return }}
                          </td>
                          <td>{{ item.price | dollar_format }}</td>
                          <td>
                            {{
                              (item.price * item.qty_to_return) | dollar_format
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="6" style="text-align: right;">
                            Subtotal
                          </td>
                          <td>{{ subTotal(orders) | dollar_format }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <!--end: Datatable-->
                  </div>
                </v-card>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    dollar_format: function(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  data: () => ({
    baseurl: process.env.VUE_APP_STRAPI_API_URL || "http://localhost:1337",
    dialog: false,
    orderTab: "Information",
    selected: 2,
    model: "tab-2",
    orders: [],
    main_order_date: "",
    billto: [],
    shipto: [],
  }),
  apollo: {
    // return_items: {
    //   client: "ecomClient",
    //   query: gql`
    //     query ReturnItems($order_id: Int) {
    //       return_items(order_id: $order_id) {
    //         id
    //         supplier
    //         price
    //         qty
    //         qty_invoiced
    //         qty_to_return
    //         item_id
    //         product_id
    //         product_name
    //         item_name
    //         sku_id
    //         supplier_name
    //         url
    //         order_id
    //         status
    //         subtotal
    //         shipping_amount
    //         grand_total
    //         created_at
    //         main_order_id
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       order_id: parseInt(this.$route.params.id),
    //     };
    //   },
    // },
  },

  created() {
    this.getVendorReturnDetail();
  },
  methods: {
    subTotal(items) {
      let sum = 0;
      if (items.length > 0) {
        for (let i in items) {
          sum += items[i].qty_to_return * items[i].price;
        }
      }
      return sum;
    },
    isInt(n) {
      return n % 1 === 0;
    },
    async getVendorReturnDetail() {
      await this.$apollo.queries.return_items.refetch();
      let order_detail = this.return_items;
      if (order_detail.length > 0) {
        this.main_order_date = order_detail[0].created_at;
      }
      this.orders = order_detail;
    },
  },
};
</script>
